import { Text, TextAppearances } from 'dss-ui-library';
import styles from './IconText.module.scss';

interface IconTextProps {
  icon: React.ReactElement;
  text: string;
  appearance?: TextAppearances;
}

export const IconText: React.FC<IconTextProps> = ({
  icon,
  text,
  appearance,
}) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.icon}>{icon}</span>
      <Text appearance={appearance ?? 't7'}>{text}</Text>
    </div>
  );
};
