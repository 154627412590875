import { Button, IconAttention, Spacer } from 'dss-ui-library';
import { IconText } from '../IconText';
import { ButtonLink } from '@ncs-frontend-monorepo/content-library';
import { NET_TV_URL } from '../../../constants/urls';

interface TVButtonsProps {
  isDisabled: boolean;
  isAddressChecked?: boolean;
  hasNetTVInternet: boolean;
  handleClick: () => void;
}

export const TVButtons: React.FC<TVButtonsProps> = ({
  isDisabled,
  isAddressChecked = false,
  hasNetTVInternet,
  handleClick,
}) => {
  if (!isAddressChecked) {
    return (
      <Button
        color="blue"
        e2e="check-availability"
        variant="secondary"
        fullWidth
        onClick={handleClick}
      >
        Adresse prüfen
      </Button>
    );
  }
  if (isDisabled && hasNetTVInternet) {
    return (
      <>
        <IconText
          text="Bei dir ist NetTV über Internet verfügbar"
          icon={<IconAttention color="blue" width={16} height={16} />}
        />
        <Spacer b={1} block />
        <ButtonLink link={{ url: NET_TV_URL }} btnFullwidth>
          Zu NetTV über Internet
        </ButtonLink>
      </>
    );
  }

  return (
    <Button
      color="red"
      e2e="order-now"
      variant="primary"
      fullWidth
      onClick={handleClick}
    >
      Jetzt bestellen
    </Button>
  );
};
